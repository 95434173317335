import {
  NumericValueType, required, email, pattern, numeric,
} from '@rxweb/reactive-forms';

export default class ContactPartnershipModel {
  private _fullName!: string;

  private _company!: string;

  private _email!: string;

  private _phoneNumber!: number;

  private _beClient!: boolean;

  private _bePartner!: boolean;

  private _gdpr!: boolean;

  constructor() {
    this._gdpr = false;
  }

  @required()
  set fullName(value: string) {
    this._fullName = value;
  }

  get fullName(): string {
    return this._fullName;
  }

  @required()
  set company(value: string) {
    this._company = value;
  }

  get company(): string {
    return this._company;
  }

  @required()
  @email()
  set email(value: string) {
    this._email = value;
  }

  get email(): string {
    return this._email;
  }

  @numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false })
  @required()
  set phoneNumber(value: number) {
    this._phoneNumber = value;
  }

  get phoneNumber(): number {
    return this._phoneNumber;
  }

  set beClient(value: boolean) {
    this._beClient = value;
  }

  get beClient(): boolean {
    return this._beClient;
  }

  set bePartner(value: boolean) {
    this._bePartner = value;
  }

  get bePartner(): boolean {
    return this._bePartner;
  }

  @required()
  @pattern({ expression: { onlyTrue: /^true/ }, messageKey: 'gdpr' })
  set gdpr(value: boolean) {
    this._gdpr = value;
  }

  get gdpr(): boolean {
    return this._gdpr;
  }
}
