
import { Component, Vue } from 'vue-property-decorator';
import NewsSnippet from '@/components/NewsSnippet.vue';
import Claims from '@/components/Claims.vue';
import Clients from '@/components/Clients.vue';
import ImageSectionPeople from '@/components/sections/image/ImageSectionPeople.vue';
import WantKnowMore from '@/components/WantKnowMore.vue';
import AboutRobotisation from '@/components/AboutRobotisation.vue';
import ContactForm from './ContactForm.vue';
import TimeTabs from '../home/TimeTabs.vue';

@Component({
  components: {
    NewsSnippet,
    Claims,
    Clients,
    ContactForm,
    AboutRobotisation,
    ImageSectionPeople,
    TimeTabs,
    WantKnowMore,
  },
})
export default class Contact extends Vue {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public nextPage = 'why-saio'

  mounted() {
    (window as any).jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0,
    });
  }
}
