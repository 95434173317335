
import { Component, Vue } from 'vue-property-decorator';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-forms';
import { generateRandomId } from '@/services/uuidService';
import * as mailStore from '@/store/modules/mail';
import * as appStore from '@/store/modules/app';
import { MailTargetType } from '@/store/modules/enums';
import ContactPartnershipModel from '../../models/contactPartnership.model';

@Component
export default class ContactForm extends Vue {
  componentId: string;

  contactFormGroup: IFormGroup<ContactPartnershipModel>;

  formBuilder: RxFormBuilder = new RxFormBuilder();

  isSendButtonTouched: boolean;

  afterSendMessage: string;

  mailStore = mailStore;

  appState = appStore;

  constructor() {
    super();
    this.contactFormGroup = this.formBuilder.formGroup(
      ContactPartnershipModel,
    ) as IFormGroup<ContactPartnershipModel>;
    this.isSendButtonTouched = false;
    this.afterSendMessage = '';
    this.componentId = generateRandomId();
  }

  goBack() {
    this.contactFormGroup = this.formBuilder.formGroup(
      ContactPartnershipModel,
    ) as IFormGroup<ContactPartnershipModel>;
    this.isSendButtonTouched = false;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  async sendForm() {
    const { currentLanguage } = this.appState.getters.getAppState;

    let mailTargetType = MailTargetType.Default;
    if (this.contactFormGroup.props.bePartner && this.contactFormGroup.props.beClient) {
      mailTargetType = MailTargetType.BothClientPartner;
    } else if (this.contactFormGroup.props.bePartner) {
      mailTargetType = MailTargetType.Partner;
    } else if (this.contactFormGroup.props.beClient) {
      mailTargetType = MailTargetType.Client;
    }

    const response = await this.mailStore.actions.sendAsync({
      name: this.contactFormGroup.props.fullName,
      email: this.contactFormGroup.props.email,
      company: this.contactFormGroup.props.company,
      phoneNumber: this.contactFormGroup.props.phoneNumber.toString(),
      target: mailTargetType,
      language: currentLanguage,
      content: '',
    });

    if (response) {
      this.afterSendMessage = this.$t('genericForm.thankYou') as string;
    } else {
      this.afterSendMessage = this.$t('genericForm.sorry') as string;
    }
    this.scrollToTop();
    this.isSendButtonTouched = true;
  }
}
