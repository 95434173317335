import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/prefer-default-export
export function generateRandomId(withHypens = false) {
  let id = uuidv4();
  if (!withHypens) {
    id = id.replace(/-/g, '');
  }
  return id;
}
